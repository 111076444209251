<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div v-if="isNotApproved" class="awaiting-approval">
      <div class="application-review">
        <div>Your application is under review</div>
        <div style="margin-top: 20px">
          <BaseButton
            label="Logout"
            backgroundColor="var(--s-pink-normal)"
            textColor="var(--state-white)"
            height="40px"
            width="100px"
            @click="handleLogout"
          />
        </div>
      </div>
    </div>
    <div class="careseeker-container">
      <div>
        <div>
          <div class="tabs">
            <!-- set active tab -->
            <span :class="{ active: isActive == 'Child care' }">
              <img
                v-if="isActive == 'child care'"
                src="@/assets/child_care.svg"
                alt="Child Care Icon"
              />
              <img
                v-else
                src="@/assets/child_care_disabled.svg"
                alt="Child Care Disabled Icon"
              />
              Child Care</span
            >
            <span :class="{ active: isActive == 'Elderly care' }">
              <img
                v-if="isActive == 'senior care'"
                src="@/assets/senior_care.svg"
                alt="Senior Care Icon"
              />
              <img
                v-else
                src="@/assets/senior_care_disabled.svg"
                alt="Senior Care Disabled Icon"
              />
              Elderly Care</span
            >
            <span :class="{ active: isActive == 'Housekeeper' }">
              <img
                v-if="isActive == 'house care'"
                src="@/assets/house_care.svg"
                alt="House Care Icon"
              />
              <img
                v-else
                src="@/assets/house_care_disabled.svg"
                alt="House Care Disabled Icon"
              />

              Housekeeper</span
            >
            <span :class="{ active: isActive == 'Tutor' }">
              <img
                v-if="isActive == 'academic care'"
                src="@/assets/academic_care.svg"
                alt="Academic Care Icon"
              />
              <img
                v-else
                src="@/assets/academic_care_disabled.svg"
                alt="Acadaemic Care Disabled Icon"
              />
              Tutor</span
            >
          </div>
        </div>

        <div class="notice">
          <img src="@/assets/notice.svg" alt="Notice Icon" />
          <p>
            All care givers have been verified (Health status & Criminal
            Background)
          </p>
        </div>
        <div v-if="!userData?.care_seeker?.has_access" class="notice-fee">
          <img src="@/assets/fee_icon.svg" alt="Notice Icon" />
          <p>Pay Access fee to engage matched Care Givers</p>
          <div class="pay-now" @click="payNow">Pay now</div>
        </div>
        <div class="content-container">
          <div class="content">
            <div class="caregivers-list">
              <div
                class="caregivers-list-individual"
                v-for="caregiver in careGiversList"
                :key="caregiver.id"
              >
                <div class="caregivers-list-first">
                  <div class="header-container">
                    <div class="profile-image-container"></div>
                    <div class="header-container-details">
                      <div class="header-container-details-first">
                        <div class="profile-image">
                          <img
                            :src="caregiver.user.profile_picture"
                            alt="Profile Image"
                          />
                        </div>
                        <div class="header-container-details-first-div">
                          <div class="name-and-svg">
                            <h4>
                              {{ caregiver.user.first_name }}
                              {{ caregiver.user.last_name }}
                            </h4>
                            <div class="header-container-details-first-div-svg">
                              <img src="@/assets/01.svg" />
                              <img src="@/assets/02.svg" />
                              <img src="@/assets/03.svg" />
                            </div>
                          </div>
                          <div>
                            {{ caregiver.state.charAt(0).toUpperCase()
                            }}{{ caregiver.state.slice(1) }}, NG
                          </div>
                          <!-- caregiver rating -->
                          <!-- <div>
                            <span
                              v-for="star in 5"
                              :key="star"
                              class="star"
                              :class="{ filled: star <= caregiver.ratings }"
                              >★</span
                            >
                            <span>4</span>
                          </div> -->
                        </div>
                      </div>
                      <div v-if="!isLargeScreen" class="caregiver-cost">
                        <div>
                          {{ caregiver.monthly_rate }}
                          <br />
                          <span> Per month</span>
                        </div>

                        <BaseButton
                          label="Contact"
                          backgroundColor="var(--s-pink-normal)"
                          textColor="var(--state-white)"
                          height="60px"
                          width="100px"
                          @click="handleClick(caregiver.id)"
                        />
                      </div>
                    </div>

                    <div v-if="isLargeScreen" class="lower-div">
                      <div class="caregiver-details">
                        {{ caregiver.bio }}
                      </div>
                      <BaseButton
                        label="Contact"
                        backgroundColor="var(--s-pink-normal)"
                        textColor="var(--state-white)"
                        height="60px"
                        width="161px"
                        @click="handleClick(caregiver.id)"
                      />
                    </div>
                  </div>
                </div>

                <div class="caregivers-list-second"></div>
              </div>
            </div>
            <div class="filter-list">
              <div v-if="!isLargeScreen">
                <div>
                  <p style="margin-bottom: 16px">Showing results for</p>
                  <div class="custom-multiselect is-mobile">
                    <Multiselect
                      id="main"
                      label="text"
                      v-model="filterValues.key"
                      track-by="value"
                      placeholder="Filter Key"
                      class="custom-multiselect"
                      :options="theOptions"
                      @input="updateTheOptions"
                    >
                      <template #caret>
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                            fill="#979797"
                          />
                        </svg>
                      </template>
                    </Multiselect>
                    <div @click="openMobileFilter" v-if="!isLargeScreen">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.498047"
                          width="43"
                          height="43"
                          rx="11.5"
                          stroke="#979797"
                        />
                        <path
                          d="M19.3333 29.998H24.6667V27.3314H19.3333V29.998ZM10 13.998V16.6647H34V13.998H10ZM14 23.3314H30V20.6647H14V23.3314Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <!-- mobile view end -->

              <transition name="slide-up">
                <div
                  v-if="!isLargeScreen && isMobileView"
                  class="modal-overlay"
                  @click.self="closeMobileFilter"
                >
                  <div class="modal-content">
                    <!-- mobile View -->
                    <div class="actual-filter-container">
                      <div>
                        <p style="margin-bottom: 10px">
                          Where do you need this care?
                        </p>
                        <div class="state-city-div">
                          <div
                            style="width: 132px"
                            :class="['custom-multiselect', 'state-select']"
                          >
                            <Multiselect
                              id="state"
                              label="text"
                              v-model="filterValues.state"
                              track-by="value"
                              placeholder="State"
                              class="custom-multiselect"
                              :options="stateOptions"
                              @input="updateCityOptions"
                            >
                              <template #caret>
                                <svg
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                                    fill="#979797"
                                  />
                                </svg>
                              </template>
                            </Multiselect>
                          </div>
                          <div
                            style="width: 211px"
                            :class="['custom-multiselect', 'city-select']"
                          >
                            <Multiselect
                              id="lga"
                              label="text"
                              v-model="filterValues.city"
                              track-by="value"
                              placeholder="City"
                              class="custom-multiselect"
                              :options="cityOptions"
                            >
                              <template #caret>
                                <svg
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                                    fill="#979797"
                                  />
                                </svg>
                              </template>
                            </Multiselect>
                          </div>
                        </div>
                      </div>
                      <div class="filter-div">
                        <h5>Filters</h5>
                        <h5 class="small-text">Clear all</h5>
                      </div>
                      <div
                        style="margin-bottom: 24px"
                        v-if="
                          isActive == 'child care' ||
                          isActive == 'academic care'
                        "
                      >
                        <label class="small-text" for="">Children</label>
                        <div class="button-div">
                          <div>
                            <span>{{ children }}</span> Child
                          </div>
                          <div style="display: flex; gap: 25px">
                            <div @click="removeChild" :disabled="children <= 1">
                              <svg
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                                  fill="#979797"
                                />
                              </svg>
                            </div>

                            <div @click="addChild">
                              <svg
                                width="26"
                                height="24"
                                viewBox="0 0 26 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                                  fill="#F59CAB"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label class="small-text" for="">Pay rate</label>

                        <RateSlider
                          :initialMinRate="payRate.pay_min"
                          :initialMaxRate="payRate.pay_max"
                          @update:rates="handleRateUpdate"
                        />
                      </div>
                      <div v-if="isActive == 'senior care'">
                        <label class="small-text" for="">Can help with</label>
                        <div>
                          <MultipleSelector
                            :subjects="senior"
                            :selectedSubjects="selectedSenior"
                            @update:selectedSubjects="updateSelectedSenior"
                          />
                        </div>
                      </div>
                      <div v-if="isActive == 'child care'">
                        <label class="small-text" for="">Age</label>

                        <MultipleSelector
                          :subjects="childrenAges"
                          :selectedSubjects="selectedChildrenAges"
                          :singleSelect="true"
                          @update:selectedSubjects="updateChildrenAges"
                          :buttonWidth="72"
                        />
                      </div>
                      <div v-if="isActive == 'house care'">
                        <label class="small-text" for="">Can help with</label>

                        <MultipleSelector
                          :subjects="house"
                          :selectedSubjects="selectedHouse"
                          :singleSelect="true"
                          @update:selectedSubjects="updateSelectedHouse"
                          :buttonWidth="72"
                        />
                      </div>
                      <div v-if="isActive == 'senior care'">
                        <label class="small-text" for="">Age</label>

                        <MultipleSelector
                          :subjects="ages"
                          :selectedSubjects="selectedAges"
                          :singleSelect="true"
                          @update:selectedSubjects="updateSelectedAges"
                          :buttonWidth="72"
                        />
                      </div>
                      <div v-if="isActive == 'senior care'">
                        <label class="small-text" for="">Preference</label>
                        <div>
                          <MultipleSelector
                            :subjects="preferences"
                            :selectedSubjects="selectedPreferences"
                            :singleSelect="true"
                            @update:selectedSubjects="updateSelectedPreferences"
                            :buttonWidth="68"
                          />
                        </div>
                      </div>
                      <div>
                        <label class="small-text" for="">Language spoken</label>
                        <!-- <p>Showing results for</p> -->
                        <MultipleSelector
                          :subjects="languages"
                          :selectedSubjects="selectedLanguages"
                          @update:selectedSubjects="updateSelectedLanguages"
                          :buttonWidth="95"
                        />
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <BaseButton
                          label="Cancel"
                          backgroundColor="transparent"
                          textColor="var(--s-green-normal-active)"
                          height="44px"
                          width="155.5px"
                          borderColor="var(--s-green-normal-active)"
                          @click="cancelFilter"
                        />
                        <BaseButton
                          label="Apply Filter"
                          backgroundColor="var(--s-pink-normal)"
                          textColor="white"
                          height="44px"
                          width="155.5px"
                          @click="applyFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- Mobile View End -->
              <div v-if="isLargeScreen">
                <div>
                  <p style="margin-bottom: 16px">Showing results for</p>
                  <div class="custom-multiselect is-mobile">
                    <Multiselect
                      id="main"
                      label="text"
                      v-model="filterValues.key"
                      track-by="value"
                      placeholder="Filter Key"
                      class="custom-multiselect"
                      :options="theOptions"
                      @input="updateTheOptions"
                    >
                      <template #caret>
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                            fill="#979797"
                          />
                        </svg>
                      </template>
                    </Multiselect>
                    <div @click="openMobileFilter" v-if="!isLargeScreen">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.498047"
                          width="43"
                          height="43"
                          rx="11.5"
                          stroke="#979797"
                        />
                        <path
                          d="M19.3333 29.998H24.6667V27.3314H19.3333V29.998ZM10 13.998V16.6647H34V13.998H10ZM14 23.3314H30V20.6647H14V23.3314Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <p style="margin-bottom: 10px">
                    Where do you need this care?
                  </p>
                  <div class="state-city-div">
                    <div
                      style="width: 132px"
                      :class="['custom-multiselect', 'state-select']"
                    >
                      <Multiselect
                        id="state"
                        label="text"
                        v-model="filterValues.state"
                        track-by="value"
                        placeholder="State"
                        class="custom-multiselect"
                        :options="stateOptions"
                        @input="updateCityOptions"
                      >
                        <template #caret>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                              fill="#979797"
                            />
                          </svg>
                        </template>
                      </Multiselect>
                    </div>
                    <div
                      style="width: 211px"
                      :class="['custom-multiselect', 'city-select']"
                    >
                      <Multiselect
                        id="lga"
                        label="text"
                        v-model="filterValues.city"
                        track-by="value"
                        placeholder="City"
                        class="custom-multiselect"
                        :options="cityOptions"
                      >
                        <template #caret>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                              fill="#979797"
                            />
                          </svg>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isLargeScreen" class="actual-filter-container">
                <div class="filter-div">
                  <h5>Filters</h5>
                  <h5 class="small-text">Clear all</h5>
                </div>
                <div
                  style="margin-bottom: 24px"
                  v-if="isActive == 'child care' || isActive == 'academic care'"
                >
                  <label class="small-text" for="">Children</label>
                  <div class="button-div">
                    <div>
                      <span>{{ children }}</span> Child
                    </div>
                    <div style="display: flex; gap: 25px">
                      <div @click="removeChild" :disabled="children <= 1">
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                            fill="#979797"
                          />
                        </svg>
                      </div>

                      <div @click="addChild">
                        <svg
                          width="26"
                          height="24"
                          viewBox="0 0 26 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                            fill="#F59CAB"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="small-text" for="">Pay rate</label>

                  <RateSlider
                    :initialMinRate="payRate.pay_min"
                    :initialMaxRate="payRate.pay_max"
                    @update:rates="handleRateUpdate"
                  />
                </div>
                <div v-if="isActive == 'senior care'">
                  <label class="small-text" for="">Can help with</label>
                  <div>
                    <MultipleSelector
                      :subjects="senior"
                      :selectedSubjects="selectedSenior"
                      @update:selectedSubjects="updateSelectedSenior"
                    />
                  </div>
                  <!-- <MultipleSelector
                :subjects="subjects"
                :selectedSubjects="selectedSubjects"
                @update:selectedSubjects="updateSelectedSubjects"/> -->
                </div>
                <div v-if="isActive == 'child care'">
                  <label class="small-text" for="">Age</label>

                  <MultipleSelector
                    :subjects="childrenAges"
                    :selectedSubjects="selectedChildrenAges"
                    :singleSelect="true"
                    @update:selectedSubjects="updateChildrenAges"
                    :buttonWidth="72"
                  />
                </div>
                <div v-if="isActive == 'house care'">
                  <label class="small-text" for="">Can help with</label>

                  <MultipleSelector
                    :subjects="house"
                    :selectedSubjects="selectedHouse"
                    :singleSelect="true"
                    @update:selectedSubjects="updateSelectedHouse"
                    :buttonWidth="72"
                  />
                </div>
                <div v-if="isActive == 'senior care'">
                  <label class="small-text" for="">Age</label>

                  <MultipleSelector
                    :subjects="ages"
                    :selectedSubjects="selectedAges"
                    :singleSelect="true"
                    @update:selectedSubjects="updateSelectedAges"
                    :buttonWidth="72"
                  />
                </div>
                <div v-if="isActive == 'senior care'">
                  <label class="small-text" for="">Preference</label>
                  <div>
                    <MultipleSelector
                      :subjects="preferences"
                      :selectedSubjects="selectedPreferences"
                      :singleSelect="true"
                      @update:selectedSubjects="updateSelectedPreferences"
                      :buttonWidth="68"
                    />
                  </div>
                </div>
                <div>
                  <label class="small-text" for="">Language spoken</label>
                  <!-- <p>Showing results for</p> -->
                  <MultipleSelector
                    :subjects="languages"
                    :selectedSubjects="selectedLanguages"
                    @update:selectedSubjects="updateSelectedLanguages"
                    :buttonWidth="102.33"
                  />
                </div>
                <BaseButton
                  label="Apply Filter"
                  backgroundColor="transparent"
                  textColor="var(--s-green-normal-active)"
                  height="60px"
                  width="327px"
                  borderColor="var(--s-green-normal-active)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import statesData from "@/data/states.js";
import RateSlider from "@/components/DoubleRateSlider.vue"; // Adjust the path as necessary
import MultipleSelector from "@/components/MultipleSelect.vue";
import { useRouter } from "vue-router";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CareSeekerHome",
  components: {
    BaseButton,
    Multiselect,
    RateSlider,
    MultipleSelector,
    DashboardLayout,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    // const careCategory = ref("senior care");
    // const setCategory = (cate) => {
    //   careCategory.value = cate;
    // };

    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const router = useRouter();
    const children = ref(1);
    const removeChild = () => {
      if (children.value > 1) {
        children.value--;
      }
    };
    const addChild = () => {
      children.value++;
    };
    //subjects
    const subjects = ref([
      "Mathematics",
      "English",
      "Science",
      "Arts",
      "Music and Drama",
      "Special Education",
      "Others",
    ]);
    const selectedSubjects = ref([]);

    const updateSelectedSubjects = (newSelectedSubjects) => {
      selectedSubjects.value = newSelectedSubjects;
    };
    //subjects end
    //senior
    const senior = ref([
      "Companionship",
      "Household tasks",
      "Mobility Assistance",
      "Personal care",
      "Specialized care",
      "Transportation",
    ]);
    const selectedSenior = ref([]);

    const updateSelectedSenior = (newSelectedSeniors) => {
      selectedSenior.value = newSelectedSeniors;
      console.log("hey", selectedSenior.value);
    };
    //senior end
    //senior
    const house = ref([
      "Carpet cleaning",
      "Laundry",
      "Bathroom cleaning",
      "Kitchen cleaning",
      "Window cleaning",
    ]);
    const selectedHouse = ref([]);

    const updateSelectedHouse = (newSelectedHouse) => {
      selectedHouse.value = newSelectedHouse;
    };
    //senior end
    //languages
    const languages = ref([
      "English",
      "Yoruba",
      "Hausa",
      "Igbo",
      "French",
      "Spanish",
    ]);
    const selectedLanguages = ref([]);

    const updateSelectedLanguages = (newSelectedLanguages) => {
      selectedLanguages.value = newSelectedLanguages;
    };
    //languages end
    //ages
    const ages = ref(["50 - 59", "60 - 69", "70 - 79", "80 - 89"]);
    const selectedAges = ref([]);

    const updateSelectedAges = (newSelectedAges) => {
      selectedAges.value = newSelectedAges;
    };
    //ages end
    // children ages
    const childrenAges = ref([
      "0-11 months",
      "1 - 3 yrs",
      "4 - 5 yrs",
      "6 - 11 yrs",
      "12+ yrs",
    ]);
    const selectedChildrenAges = ref([]);

    const updateChildrenAges = (newSelectedAges) => {
      selectedChildrenAges.value = newSelectedAges;
    };
    //children ages end
    //preferences
    const preferences = ref(["Live in", "Live out", "Either"]);
    const selectedPreferences = ref([]);

    const updateSelectedPreferences = (newSelectedPreferences) => {
      selectedPreferences.value = newSelectedPreferences;
    };
    //ages end

    const payRate = ref({ pay_min: 10000, pay_max: 100000 }); // Example initial data

    const handleRateUpdate = ({ minRate, maxRate }) => {
      payRate.value.pay_min = minRate;
      payRate.value.pay_max = maxRate;
    };
    const filterValues = ref({
      key: "",
      state: "",
      local_government: "",
      pay_rate: "",
      // child care
      no_of_children: "",
      age_range: "",
      // house care
      house_care_category: "",
      // senior care
      senior_care_category: "",
      age: "",
      preference: "",
      //end
      language: "",
    });
    const isActive = ref("");
    const determiningValue = ref("academic care");
    const theOptions = ref([]);
    const setActiveTab = (act) => {
      isActive.value = act;
      determiningValue.value = act;
      filterValues.value.key = "";
    };
    const stateOptions = statesData.states.map((state) => ({
      value: state.state,
      text: state.state,
    }));

    const cityOptions = ref([]);

    const updateCityOptions = (state) => {
      const selectedStateData = statesData.states.find(
        (s) => s.state === state
      );
      cityOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };
    const careGiversList = ref([
      // {
      //   id: 1,
      //   name: "Taiwo Awoniyi",
      //   location: "Lagos, NG",
      //   experience: "Errands, housekeeping and meal prep",
      //   details:
      //     "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. ",
      //   cost: "N3000",
      //   ratings: 5,
      //   image: "woman.png",
      // },
      // {
      //   id: 2,
      //   name: "Taiwo Awoniyi",
      //   location: "Lagos, NG",
      //   experience: "Errands, housekeeping and meal prep",
      //   details:
      //     "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. ",
      //   cost: "N3000",
      //   ratings: 5,
      //   image: "woman.png",
      // },
      // {
      //   id: 3,
      //   name: "Taiwo Awoniyi",
      //   location: "Lagos, NG",
      //   experience: "Errands, housekeeping and meal prep",
      //   details:
      //     "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. ",
      //   cost: "N3000",
      //   ratings: 5,
      //   image: "woman.png",
      // },
      // {
      //   id: 4,
      //   name: "Taiwo Awoniyi",
      //   location: "Lagos, NG",
      //   experience: "Errands, housekeeping and meal prep",
      //   details:
      //     "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. ",
      //   cost: "N3000",
      //   ratings: 5,
      //   image: "woman.png",
      // },
    ]);

    const updateTheOptions = () => {
      if (determiningValue.value === "academic care") {
        theOptions.value = [
          { text: "Mathematics", value: "mathematics" },
          { text: "English", value: "english" },
          // More options for academic care
        ];
      } else if (determiningValue.value === "child care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "Either", value: "either" },
          // More options for child care
        ];
      } else if (determiningValue.value === "senior care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "I am not sure", value: "not sure" },
          // More options for child care
        ];
      } else if (determiningValue.value === "house care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "I am not sure", value: "not sure" },
          // More options for child care
        ];
      }
    };
    const handleClick = (id) => {
      console.log("id here", id);
      console.log("okay now", userData.value.care_seeker.has_access);
      if (!userData.value.care_seeker.has_access) {
        return showToast("You have not paid the access fee", "error");
      }
      router.push(`caregiver-profile/${id}`);
    };

    watch(determiningValue, () => {
      updateTheOptions();
    });
    const isLoading = ref(true);
    const userData = ref({});
    const isNotApproved = ref(false);
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const fetchProfile = () => {
      const request = new Request(`/profile/${userProfile.id}/`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is profile data", res);
          userData.value = { ...res };
          localStorage.setItem("_userProfile", JSON.stringify(res));

          isNotApproved.value =
            res.care_seeker.approval_status == 0 ? true : false;
          isActive.value = res.care_seeker.category.name;
          // isNotApproved.value = false;
        }
      });
    };
    const fetchCaregivers = () => {
      const request = new Request(`/care-seeker/match/care-givers`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here match", res);
          careGiversList.value = [...res];
        }
      });
    };

    const isLargeScreen = ref(window.innerWidth > 1200);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 1200;
      console.log("inner", window.innerWidth);
    };
    const payNow = () => {
      router.push("/careseeker/payment");
    };
    const isMobileView = ref(false);
    const openMobileFilter = () => {
      isMobileView.value = true;
    };
    onMounted(() => {
      updateTheOptions();
      fetchProfile();
      fetchCaregivers();
    });

    const closeMobileFilter = () => {
      isMobileView.value = false;
    };

    const applyFilter = () => {
      // Logic to apply the filter
      closeMobileFilter();
    };
    const cancelFilter = () => {
      // Logic to apply the filter
      closeMobileFilter();
    };
     const handleLogout = () => {
      router.push("/login")
    }
    return {
      isActive,
      setActiveTab,
      careGiversList,
      filterValues,
      updateTheOptions,
      theOptions,
      stateOptions,
      updateCityOptions,
      handleRateUpdate,
      payRate,
      updateSelectedSubjects,
      selectedSubjects,
      subjects,
      senior,
      selectedSenior,
      updateSelectedSenior,
      languages,
      selectedLanguages,
      updateSelectedLanguages,
      ages,
      selectedAges,
      updateSelectedAges,
      preferences,
      selectedPreferences,
      updateSelectedPreferences,
      addChild,
      removeChild,
      children,
      childrenAges,
      updateChildrenAges,
      selectedChildrenAges,
      house,
      selectedHouse,
      updateSelectedHouse,
      handleClick,
      isLoading,
      isNotApproved,
      payNow,
      showToast,
      toastManager,
      isLargeScreen,
      handleResize,
      userData,
      isMobileView,
      openMobileFilter,
      closeMobileFilter,
      applyFilter,
      cancelFilter,
      handleLogout
    };
  },
};
</script>

<style scoped>
.content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  margin-top: 32px;
}
.careseeker-container {
  /* Add necessary styles here */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tabs {
  display: flex;
  justify-content: center;
  gap: 64px;
  padding-bottom: 8px;

  margin-top: 32px;
}
.tabs span {
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.tabs .active {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;

  border-bottom: 5px solid var(--s-pink-normal);
}
.notice {
  text-align: center;
  padding: 10px;
  background-color: #ffe4e1;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 32px;
  /* margin-bottom: 32px; */
}
.notice-fee {
  text-align: center;
  padding: 10px;
  background-color: #ff9500;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  /* margin-top: 32px; */
  margin-bottom: 32px;
  color: white;

  font-family: Inter;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.pay-now {
  width: 170px;
  height: 40px;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.content {
  display: flex;
  gap: 97px;
}
.notice p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.header-container-details-first-div {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.header-container-details-first-div-svg {
  gap: 16px;
  display: flex;
  align-items: center;
}
.header-container-details {
  display: flex;
  justify-content: space-between;
}
.header-container-details-first {
  display: flex;
  gap: 18px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image {
  width: 115px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-image img {
  width: 110px;
  height: 120px;
  border-radius: 12px;
}
h4 {
  margin: 0px;

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.name-and-svg {
  display: flex;
  align-self: start;
  gap: 24px;
}
.lower-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.caregiver-details {
  width: 382px;
}
.caregivers-list-first {
  width: 672px;
}
.caregivers-list {
  width: 672px;
}
.caregivers-list-individual {
  width: 100%;
}
.caregiver-cost {
  font-size: 24px;
  font-weight: 700;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: right;
}
.caregiver-cost span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.header-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #979797;
  margin-bottom: 38px;
}
.star {
  font-size: 20px;
  margin-right: 6px;
}

.star.filled {
  color: #ffcc00;
}

.star:not(.filled) {
  color: #ddd;
}
.actual-filter-container {
  background-color: #ececec4d;
  border-radius: 12px;
  padding: 10px 16px 10px 16px;
}
.filter-list {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  width: 359px;
}
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
.state-select {
  position: relative;
  z-index: 2;
}

.city-select {
  position: relative;
  z-index: 1;
}

.state-select .multiselect--active .multiselect__content-wrapper {
  z-index: 3;
}

.city-select .multiselect--active .multiselect__content-wrapper {
  z-index: 2;
}
.state-city-div {
  display: flex;
  gap: 16px;
}
h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px 0px 10px 0px;
}
.small-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 10px;
}
.filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-div {
  display: flex;
  justify-content: space-between;
}
/* awaiting approval */
.awaiting-approval {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.96); /* Changed to dark shade */
  z-index: 1000; /* Ensure it is above other elements */
}

.application-review {
  width: 400px;
  height: 200px;
  font-size: 18px;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* end */
@media (max-width: 800px) {
  .caregivers-list {
    max-width: 320px;
    width: 100%;
  }
  .filter-list {
    max-width: 320px;
    width: 100%;
  }
  .content {
    flex-direction: column-reverse;
  }
  .tabs {
    display: flex;
    justify-content: center;
    gap: 4px;
    padding-bottom: 8px;
    margin-top: 32px;
  }
  .tabs span {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }
  .tabs .active {
    white-space: nowrap;
    border-bottom: 5px solid var(--s-pink-normal);
    font-size: 10px;
    font-weight: 700;
    line-height: 20.4px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .caregivers-list-first {
    width: 340px;
  }
  h4 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  .is-mobile {
    display: flex;

    gap: 16px;
  }

  .modal-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .modal-content {
    background: white;
    border-radius: 12px 12px 0 0;
    padding: 16px;
    width: 100%;
    max-height: 50%;
    overflow-y: auto;
  }

  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: transform 0.3s ease-out;
  }

  .slide-up-enter {
    transform: translateY(100%);
  }

  .slide-up-leave-to {
    transform: translateY(100%);
  }
  .name-and-svg {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  h4 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.25px;
    text-align: left;
  }
  .header-container-details-first-div-svg img {
    width: 10.23px;
    height: 12px;
  }
  .caregiver-cost {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 10.2px;
    letter-spacing: 0.25px;
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
  }
  .header-container-details-first {
    gap: 8px;
  }
}
</style>
