<template>
  <div class="overall-container">
    <header class="fixed-header">
      <nav class="navbar">
        <img class="main-logo" src="@/assets/full_logo.svg" alt="Logo" />
        <div class="header">
          <!-- <div class="search-wrapper">
            <input type="text" placeholder="Search" v-model="searchQuery" />
            <svg
              class="search-icon"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6794 11H11.8894L11.6094 10.73C12.5894 9.59 13.1794 8.11 13.1794 6.5C13.1794 2.91 10.2694 0 6.67944 0C3.08944 0 0.179443 2.91 0.179443 6.5C0.179443 10.09 3.08944 13 6.67944 13C8.28944 13 9.76944 12.41 10.9094 11.43L11.1794 11.71V12.5L16.1794 17.49L17.6694 16L12.6794 11ZM6.67944 11C4.18944 11 2.17944 8.99 2.17944 6.5C2.17944 4.01 4.18944 2 6.67944 2C9.16944 2 11.1794 4.01 11.1794 6.5C11.1794 8.99 9.16944 11 6.67944 11Z"
                fill="#979797"
              />
            </svg>
          </div> -->
          <div v-if="isLargeScreen" class="user-info">
            <img src="@/assets/bell.svg" alt="Child Care Icon" />
            <div class="user-avatar">SA</div>
          </div>
           <BaseButton
                label="Log Out"
                backgroundColor="transparent"
                textColor="var(--s-green-normal-active)"
                height="60px"
                width="100%"
                borderColor="var(--s-green-normal-active)"
                @click="logout"
              />
        </div>
      </nav>
    </header>
    <div class="main-container">
      <div class="sidebar">
        <nav class="sidebar-nav">
          <div
            @click="setTab('general')"
            class="nav-link"
            :class="{ active: tab == 'general' }"
          >
            <img
              v-if="tab == 'general'"
              class="icon"
              src="@/assets/general_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/general_normal.svg"
              alt="Logo"
            />
           <span v-if="isLargeScreen"> General</span>
          </div>
          <div
            @click="setTab('careseekers')"
            class="nav-link"
            :class="{ active: tab == 'careseekers' }"
          >
            <img
              v-if="tab == 'careseekers'"
              class="icon"
              src="@/assets/admin_careseeker_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/admin_careseeker_normal.svg"
              alt="Logo"
            />
           <span v-if="isLargeScreen"> Careseekers</span>
          </div>
          <div
            @click="setTab('caregivers')"
            class="nav-link"
            :class="{ active: tab == 'caregivers' }"
          >
            <img
              v-if="tab == 'caregivers'"
              class="icon"
              src="@/assets/admin_caregivers_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/admin_caregivers_normal.svg"
              alt="Logo"
            />
           <span v-if="isLargeScreen"> Caregivers </span>
          </div>
          <div
            @click="setTab('reports')"
            class="nav-link"
            :class="{ active: tab == 'reports' }"
          >
            <img
              v-if="tab == 'reports'"
              class="icon"
              src="@/assets/admin_reports_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/admin_reports_normal.svg"
              alt="Logo"
            />
            <span v-if="isLargeScreen">Reports </span>
          </div>
           <div
            @click="setTab('verification')"
            class="nav-link"
            :class="{ active: tab == 'verification' }"
          >
            <img
              v-if="tab == 'verification'"
              class="icon"
              src="@/assets/admin_reports_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/admin_reports_normal.svg"
              alt="Logo"
            />
            <span v-if="isLargeScreen">Verification</span>
          </div>
            <div
            @click="setTab('list')"
            class="nav-link"
            :class="{ active: tab == 'list' }"
          >
            <img
              v-if="tab == 'list'"
              class="icon"
              src="@/assets/admin_reports_active.svg"
              alt="Logo"
            />
            <img
              v-else
              class="icon"
              src="@/assets/admin_reports_normal.svg"
              alt="Logo"
            />
            <span v-if="isLargeScreen">List</span>
          </div>
        </nav>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
      
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "AdminLayout",
  components:{
    BaseButton
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const searchQuery = ref("");
    const tab = ref("");

    const setTab = (newTab) => {
      tab.value = newTab;
      router.push(`/admin/${newTab}`);
    };

    const updateTabFromRoute = () => {
      const currentPath = route.path.split("/").pop();
      if (currentPath) {
        tab.value = currentPath;
      } else {
        tab.value = "general"; // Default tab if no specific route is matched
      }
    // tab.value = "general";
    };
const logout = () => {
      console.log("Logged out");
      router.push("/login");
      localStorage.clear();
      // Implement logout functionality here
    };
    watch(route, () => {
      updateTabFromRoute();
    });

    onMounted(() => {
      updateTabFromRoute();
    });
  const isLargeScreen = ref(window.innerWidth > 800);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 800;
      console.log("inner", window.innerWidth);
    };
    return {
      searchQuery,
      tab,
      setTab,
      logout,
      isLargeScreen,
      handleResize
    };
  },
};
</script>



<style scoped>
.navbar {
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  width: 100%;
  max-width: 1184px;
  box-sizing: border-box;
  background-color: var(--state-white);
  align-items: center;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
  width: 100%;
  display: flex;
  justify-content: center;
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--state-white);
}

.main-container {
  display: flex;
  width: 100%;
  margin-top: 94px; /* Adjust this value to match the height of your header */
}
.overall-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url("@/assets/Layer_3.png"); /* Replace with the actual path to your image */
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-color: #fffbfc;
  min-height: 100vh;
  height: 100%;
}

.sidebar {
  width: 324px;
  background-color: var(--s-green-light);
  padding: 80px 24px 80px 48px;
  height: 100vh;
  position: fixed;
  top: 94px; /* Adjust this value to match the height of your header */
  box-sizing: border-box;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.logo {
  width: 120px;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.nav-link {
  padding: 12px 16px;
  color: #2e7d32;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.active {
  background-color: var(--s-green-normal);
  color: var(--state-white);
  border-radius: 12px;
}

.main-content {
  flex: 1;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}

.search-bar {
  width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-info {
  display: flex;
  gap: 16px;
  align-items: center;
}
.user-info img {
  width: 32.82px;
  height: 40px;
  gap: 0px;
  
}
.bell-icon {
  margin-right: 16px;
}

.user-avatar {
  width: 66px;
  height: 64px;
  background-color: var(--s-green-normal);
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard {
  padding: 16px;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.stat-card {
  background-color: #c8e6c9;
  padding: 16px;
  flex: 1;
  margin: 0 8px;
  text-align: center;
}

.requests-reports {
  display: flex;
  justify-content: space-between;
}

.requests,
.reports {
  flex: 1;
  margin: 0 8px;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.links {
  display: flex;
  gap: 30px;
}

.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}
.links a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.links router-link-active {
  font-weight: bold;
}
.icon {
  width: 23px;
  height: 20px;
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 315px;
  height: 48px;
  padding: 10px 10px 10px 56px;

  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.search-icon {
  position: absolute;
  left: 19px;
}

.content {
  margin-left: 324px;
  padding: 16px;
  width: calc(100% - 324px);
  box-sizing: border-box;
}
@media (max-width: 800px) {
 .navbar{
padding: 20px;
 }
 .main-logo{
  height: 40px;
  width: 130px;
 }
 .sidebar{
  width: 80px;
  padding: 40px 10px 40px 10px;
 }
 .content{
  margin-left: 80px
 }
 

}
</style>
