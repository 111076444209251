<template>
  <div>
    <div
      class="message-item"
      :class="{ selected: selectedMessage === groupName }"
      @click="selectMessage()"
    >
      <Avatar :initials="initials(firstMessage)" />
      <div class="message-content">
        <div class="message-header">
          <span class="name">{{ name(firstMessage) }}</span>
          <span class="date">{{ formattedDate(firstMessage.timestamp) }}</span>
        </div>
        <div class="message-body">{{ firstMessage.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/AvatarComponent.vue";

export default {
  components: {
    Avatar,
  },
  props: {
    messageGroup: {
      type: Array,
      required: true,
    },
    userCareType: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    selectedMessage: {
      type: String,
      default: null,
    },
    selectedMessageRoom: {
      type: Object,
      default: null,
    },
  },
  computed: {
    firstMessage() {
      // Return the first message in the messageGroup array
      console.log("group ooooooo", this.messageGroup[0]);
      return this.messageGroup[0];
    },
  },
  methods: {
    name(sender) {
      console.log("first message", sender);
      let recipientNameToShow;
      if (this.userCareType === "Care giver") {
        if (sender.recipient.type == "Care giver") {
          recipientNameToShow = `${sender.sender?.first_name || ""} ${
            sender.sender?.last_name || ""
          }`.trim();
        } else if (sender.recipient.type == "Care seeker") {
          recipientNameToShow = `${sender.recipient?.first_name || ""} ${
            sender.recipient?.last_name || ""
          }`.trim();
        }
      } else {
        if (sender.recipient.type == "Care giver") {
          recipientNameToShow = `${sender.recipient?.first_name || ""} ${
            sender.recipient?.last_name || ""
          }`.trim();
        } else if (sender.recipient.type == "Care Seeker") {
          recipientNameToShow = `${sender.sender?.first_name || ""} ${
            sender.sender?.last_name || ""
          }`.trim();
        }
      }

      return recipientNameToShow;
    },
    avatarSrc(sender) {
      return sender?.profile_picture || "";
    },
    initials(sender) {
     
       let recipientNameToShow;
      if (this.userCareType === "Care giver") {
        if (sender.recipient.type == "Care giver") {
          recipientNameToShow = `${sender.sender?.first_name[0] || ""} ${
            sender.sender?.last_name[0] || ""
          }`.toUpperCase();
        } else if (sender.recipient.type == "Care seeker") {
          recipientNameToShow = `${sender.recipient?.first_name[0] || ""} ${
            sender.recipient?.last_name[0] || ""
          }`.toUpperCase();
        }
      } else {
        if (sender.recipient.type == "Care giver") {
          recipientNameToShow = `${sender.recipient?.first_name[0] || ""} ${
            sender.recipient?.last_name[0] || ""
          }`.toUpperCase();
        } else if (sender.recipient.type == "Care Seeker") {
          recipientNameToShow = `${sender.sender?.first_name[0] || ""} ${
            sender.sender?.last_name[0] || ""
          }`.toUpperCase();
        }
      }
      return recipientNameToShow;
    },
    formattedDate(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleString() : "";
    },
    // selectMessage() {
    //   this.$emit('select', this.messageGroup[0]);
    //      this.$emit('select', this.messageGroup[0].group_name);
    // }
    selectMessage() {
      this.$emit("select", {
        group: this.messageGroup,
        groupName: this.messageGroup[0].group_name,
      });
    },
  },
};
</script>

<style scoped>
.message-item {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;
  gap: 8px;
  border-radius: 12px;
  cursor: pointer;
}

.selected {
  background-color: #f0f0f0;
}

.message-content {
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}

.date {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.message-body {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
</style>
