<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div class="payment-container">
      <div class="payment-main">
        <h2>Payment Summary</h2>
        <!-- <div class="reason">
          <span>Why am I been charged access fee?</span>
          <ul>
            <li>Reason 1</li>
            <li>Reason 2</li>
          </ul>
        </div> -->
        <div class="cost">
          <div>Access Fee</div>
          <div>15000</div>
        </div>
        <div class="cost-total">
          <div><span>Total</span></div>
          <div><span>15000</span></div>
        </div>
        <div style="margin-bottom: 35px; margin-top: 32px">
          By making this payment you authorize us to charge your card and you
          agree to our terms.
        </div>
        <div>
          By clicking <span>“Pay now”</span> our account will be charged
          <span>N15000</span> plus applicable taxes and charges.
        </div>
        <div @click="payPaystack" class="pay-with-paystack">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9609 0.261719H1.86109C1.21961 0.261719 0.631582 0.791719 0.631582 1.48072V3.70672C0.631582 4.39572 1.21961 4.97872 1.86109 4.97872H21.9609C22.6558 4.97872 23.1904 4.39572 23.2438 3.70672V1.53372C23.2438 0.791719 22.6558 0.261719 21.9609 0.261719ZM21.9609 12.7697H1.86109C1.54035 12.7697 1.21961 12.9287 0.952323 13.1407C0.738496 13.3527 0.578125 13.6707 0.578125 14.0417V16.2677C0.578125 16.9567 1.16615 17.5397 1.80763 17.5397H21.9074C22.6024 17.5397 23.1369 17.0097 23.1904 16.2677V14.0417C23.2438 13.2997 22.6558 12.7697 21.9609 12.7697ZM13.1939 18.9707H1.86109C1.54035 18.9707 1.21961 19.0767 1.00578 19.3417C0.791953 19.5537 0.631582 19.8717 0.631582 20.2427V22.4687C0.631582 23.1577 1.21961 23.7407 1.86109 23.7407H13.1405C13.8354 23.7407 14.37 23.1577 14.37 22.4687V20.1897C14.4235 19.5537 13.8889 18.9707 13.1939 18.9707ZM23.2438 6.51572H1.86109C1.54035 6.51572 1.21961 6.62172 1.00578 6.88672C0.791953 7.09872 0.631582 7.41672 0.631582 7.78772V10.0137C0.631582 10.7027 1.21961 11.2857 1.86109 11.2857H23.1904C23.8853 11.2857 24.4199 10.7027 24.4199 10.0137V7.78772C24.4733 7.04572 23.9388 6.51572 23.2438 6.51572Z"
              fill="#00C3F7"
            />
          </svg>
          Pay with Paystack
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";

import { ref } from "vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";
// import { useRouter } from "vue-router";
export default {
  name: "PaymentPage",
  components: {
    DashboardLayout,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    const amount = ref(15000)
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    // const router = useRouter();
    const isLoading = ref(true);
    const payPaystack = () => {
      isLoading.value = true;

      const request = new Request(`/payment/`);
      const paymentPayload = {
       description: "Payment",
        amount: amount.value,
        purpose:"Access"
      };
      const rawData = JSON.stringify(paymentPayload);
      request.post(rawData, (res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error save acc:", err);
          showToast("Payment Failed", "error");
        } else {
          console.log("here is response save account", res);
          if(res.status == "success"){
            // window.href = res.data.authorization_url
             window.open(res.data.authorization_url, '_blank');
          }
        showToast("Payment Successful", "success");
        }
      });
     // router.push("/careseeker/payment-status");
    };
    // https://api.succour.care/v1/payment/verify_payment/?reference=gr52nkxidm
    return {
      payPaystack,
      toastManager,
      showToast,
      LoadingSpinner,
    };
  },
};
</script>
<style scoped>
.payment-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.payment-main {
  margin-top: 59px;
  background-color: #fef5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 70px;
  width: 650px;
  border-radius: 12px;
}
.cost {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #979797;
}
.cost-total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 16px;
}
.pay-with-paystack {
  width: 311px;
  height: 60px;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid black;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 35px;
  cursor: pointer;
}
span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.reason {
  width: 100%;
  margin-top: 32px;
}
ul {
  list-style-position: inside;
}
h2 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
</style>